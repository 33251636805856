import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Layout from "@components/Layout";
import Header from "@components/Header";
import SEO from "@components/SEO";
import PageHeading from "@components/PageHeading";
import Link from "@components/Link";
import LabeledIcon from "@components/LabeledIcon";

import LinkedInIcon from "@images/icons/linkedin.svg";
import TwitterIcon from "@images/icons/twitter.svg";

const SocialPage = ({ location }) => {
  const { t } = useTranslation();

  return (
    <Layout>
      <SEO
        title={t("social.title")}
        description={t("social.description")}
        pathname={location.pathname}
      />
      <Header />
      <main>
        <div className="main-content-wrapper">
          <PageHeading>{t("social.heading")}</PageHeading>
          <ul className="social-links">
            <li>
              <Link
                href="https://www.linkedin.com/in/viktortylecek/"
                target="_blank"
                rel="noopener"
                outside
              >
                <LabeledIcon icon={LinkedInIcon} label="LinkedIn" />
              </Link>
            </li>
            <li>
              <Link
                href="https://twitter.com/tylecvik"
                target="_blank"
                rel="noopener"
                outside
              >
                <LabeledIcon icon={TwitterIcon} label="Twitter" />
              </Link>
            </li>
          </ul>
        </div>
      </main>
    </Layout>
  );
};

SocialPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default SocialPage;

import React from "react";

import CloseButton from "@components/CloseButton";

import { CLOSE_OVERLAY } from "@actions/overlay";

import { OverlayDispatch } from "@contexts/overlay";

import { getDisplayName } from "@utils/component";

function withOverlay(WrappedComponent, className = "") {
  class WithOverlay extends React.Component {
    constructor(props) {
      super(props);

      this.close = this.close.bind(this);
    }

    close() {
      const dispatch = this.context;

      dispatch({ type: CLOSE_OVERLAY });
    }

    render() {
      return (
        <div className={`overlay ${className}`}>
          <CloseButton onClick={this.close} />
          <div className="overlay-content">
            <WrappedComponent {...this.props} />
          </div>
        </div>
      );
    }
  }

  WithOverlay.displayName = `WithOverlay(${getDisplayName(WrappedComponent)})`;
  WithOverlay.contextType = OverlayDispatch;

  return WithOverlay;
}

export default withOverlay;

import React, { useContext } from "react";

import Responsive from "@components/Responsive";
import MenuButton from "@components/MenuButton";
import Menu from "@components/Menu";
import { MenuOverlay } from "@components/overlays";

import { OPEN_OVERLAY } from "@actions/overlay";

import { OverlayDispatch } from "@contexts/overlay";

const Header = () => {
  const dispatch = useContext(OverlayDispatch);
  const mobile = () => (
    <header>
      <MenuButton
        onClick={() => dispatch({ type: OPEN_OVERLAY, overlay: MenuOverlay })}
      />
    </header>
  );
  const desktop = () => (
    <header>
      <Menu />
    </header>
  );

  return <Responsive renderMobile={mobile} renderDesktop={desktop} />;
};

export default Header;

import React from "react";
import PropTypes from "prop-types";

const LabeledIcon = ({ icon, label }) => {
  return (
    <div className="labeled-icon">
      <div className="icon">
        <img src={icon} alt="" />
      </div>
      <div className="label">{label}</div>
    </div>
  );
};

LabeledIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default LabeledIcon;

/* eslint-disable testing-library/render-result-naming-convention */
import React, { useContext } from "react";
import PropTypes from "prop-types";

import { Desktop } from "@contexts/desktop";

import { DESKTOP_CLASSNAME, MOBILE_CLASSNAME } from "../settings";

const isSsr = typeof window === "undefined";

const Responsive = ({ renderMobile, renderDesktop }) => {
  const isDesktop = useContext(Desktop);
  const mobileOrig = renderMobile();
  const desktopOrig = renderDesktop();
  const mobile = React.cloneElement(mobileOrig, {
    className: `${mobileOrig.props.className || ""} ${MOBILE_CLASSNAME}`,
  });
  const desktop = React.cloneElement(desktopOrig, {
    className: `${desktopOrig.props.className || ""} ${DESKTOP_CLASSNAME}`,
  });

  if (isSsr) {
    return (
      <>
        {mobile}
        {desktop}
      </>
    );
  }

  return isDesktop ? desktop : mobile;
};

Responsive.propTypes = {
  renderMobile: PropTypes.func.isRequired,
  renderDesktop: PropTypes.func.isRequired,
};

export default Responsive;

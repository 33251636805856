import React from "react";
import { useTranslation } from "react-i18next";

const CloseButton = (props) => {
  const { t } = useTranslation();

  return (
    <button
      className="close-button"
      type="button"
      aria-label={t("button.close.label")}
      {...props}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <line x1="18" y1="6" x2="6" y2="18"></line>
        <line x1="6" y1="6" x2="18" y2="18"></line>
      </svg>
    </button>
  );
};

export default CloseButton;
